@charset 'UTF-8';
@use 'sass:math';
@use './var' as *;
@use './functions' as *;



// Media Queries
@mixin media( $data, $max: false )
{
  $key: map-get( $breakpoints, $data );

  @if( $data == 'xs' ){

    @if $max == true {
      $key: map-get( $breakpoints, sm ) - 1px;
      @media screen and ( max-width: $key ) {
        @content;
      }
    } @else {
      @content;
    }

  } @else {

    @media screen and ( min-width: $key ) {
      @content;
    }

  }
}



// Container
@mixin baseContainer( $y-padding: 0, $x-padding: $container-side-padding, $width: $container-max-width )
{
  margin: 0 auto;
  padding: $y-padding $x-padding;
  width: 100%;
  max-width: $width;
}



// Make grid
@mixin flexProperty($width)
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}



// grid item
@mixin makeGridColumns($size, $num)
{
  .#{$size}-#{$num} {
    @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
    margin: 0;
    padding: calc( var(--grid-gutter) * .5 );
  }
  .#{$size}-#{$num}-center {
    @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
    margin: 0 auto;
    padding: calc( var(--grid-gutter) * .5 );
  }
  .#{$size}-#{$num}-offset {
    @include flexProperty( math.div(100%, math.div($grid-columns, $num)) );
    margin-left: ( math.div(100%, math.div($grid-columns, $num)) );
    padding: calc( var(--grid-gutter) * .5 );
  }
  .#{$size}-col-#{$num} {
    @include flexProperty( math.div(100%, $num) );
    margin: 0;
    padding: calc( var(--grid-gutter) * .5 );
  }
}



// Hoverの指定
@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}



// SVG
@mixin setSvgIcon( $svg: $arrow-right, $color: $base, $size: 1em )
{
  background-image: set-svg($svg, $color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: $size;
  height: $size;
}



// ファイルアップロード
@mixin file-selector-button( $x: 1em, $y: 1em )
{
  background-color: $gray-100;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: $gray-dark;
  margin: ($x * -1) ($y * -1);
  margin-inline-end: $x;
  padding: $y $x;
  pointer-events: none;
}



// Container Query
@mixin containerQuery( $width: $container-query-width )
{
  @container ( min-width: #{$width} ) {
    @content;
  }
}
