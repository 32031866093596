@charset 'UTF-8';
@use './modules/global/index' as *;

#top .construct-main {
  padding: initial !important;
  margin-top: 64px;
  @include media(lg) {
    margin-top: 129px;
  }
}

.top-section-wrapper, .top-sm-link {
  padding-left: 5%;
  padding-right: 5%;
  @include media(md) {
    padding-left: 70px;
    padding-right: 70px;
  }
}

.top-section-wrapper {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
  background-color: #fff;
  &.top-bg-green {
    background-color: #F4F7F5;
  }
  &.caution {
    padding-top: 0;
  }
}

.top-inner-container, .top-under-inner, .top-smlink-inner {
  max-width: 1340px;
  margin: 0 auto;
  container-type: inline-size;
}

/*ホバー時のふわっ*/
a {
  text-decoration: none;
  transition: background-color var(--base-transition), color var(--base-transition);
}

/* TOPビジュアル */
/*.top-visual-wrapper {
  position: relative;
}*/

.top-visual-inner {
  height: 67vh;
  min-height: 570px;
  position: relative;
  
  .top-slide {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    .top-visual-swiper {
      height: 100%;
      .swiper-wrapper {
        .swiper-slide {
          .slide-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .top-filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(26,41,37, 0.15);
      z-index: 1;
  }
    .top-slide-blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //filter: blur(60px);
      //mix-blend-mode: multiply;
      @include media(lg) {
        width: 50%;
      }
      z-index: 1;
      .top-blur-img {
        width: 100%;
        height: 100%;
      }
    }

    .top-slide-h {
      color: $white;
      position: absolute;
      top: 60%;
      left: 0%;
      transform: translateY(-50%);
      padding-left: 5%;
      z-index: 1;
      text-shadow: 0px 0px 8px rgb(2 26 14 / 20%);
      @include media(md) {
        padding-left: 70px;
      }
      @include media(lg) {
        top: 40%;
      }
      .slide-h-main {
        font-family: 'Shippori Mincho';
        font-weight: 300;
        font-size: 2.6rem;
        line-height: 1.5;
        @include media(lg) {
          font-size: 4.2rem;
          line-height: initial;
        }
      }
      .slide-h-sub {
        font-size: 1.5rem;
        margin-top: 2rem;
        line-height: 1.5;
        @include media(lg) {
          font-size: 1.8rem;
          line-height: 1.8;
        }
      }
      .slide-h-guide {
        display: inline-block;
        color: #fff;
        margin-top: 2rem;
        .slide-h-guide-text {
          font-size: 1.35rem;
          padding-bottom: .2rem;
          border-bottom: solid 1px #fff;
          display: inline-block;
          @include media(lg) {
            font-size: 1.5rem;
          }
        }
        .slide-h-guide-arrow {
          display: inline-block;
          vertical-align: middle;
          color: #fff;
          line-height: 1;
          position: relative;
          width: 3rem;
          height: 3rem;
          border: 0.075em solid currentColor;
          background: currentColor;
          border-radius: 50%;
          box-sizing: content-box;
          margin-left: 1rem;
          &::after {
            content: '';
            color: #245e42;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            border-width: 0.3em 0.5em;
            border-left-color: currentColor;
            border-right: 0;
            transform: translateX(15%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }
}

.top-slide .swiper-slide-active .slide-image,
.top-slide .swiper-slide-duplicate-active .slide-image,
.top-slide .swiper-slide-prev .slide-image {
    animation: zoomanime 5s linear 0s normal both;
}

@keyframes zoomanime {
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

/* TOP採用ボタン */
.top-rec-btn {
  width: calc(50% - .7rem);
  height: 65px;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: #245e42;
  border-radius: 5px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
  @include media(md) {
    flex-direction: column;
    justify-content: center;
    width: 100px;
    height: 100px;
    top: -30px;
  }
  @media (min-width: 1132px) {
    top: -80px;
    right: -50px;
  }

  // hover
  @include hover(){
    color: #fff;
    background-color: #2b704f;
  }
  .top-rec-circle {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    line-height: 1;
    position: relative;
    width: 4rem;
    height: 4rem;
    border: 0.075em solid currentColor;
    background: currentColor;
    border-radius: 50%;
    margin: 0 1rem;
    @include media(lg) {
      margin-bottom: .5rem;
    }
    &::before {
      content: "";
      background-image: set-svg($recruit, #245e42);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

/* TOPビジュアル下 */
.top-under-wrapper {
  width: 100%;
  margin-top: -10vh;
  padding-right: 5%;
  padding-left: 5%;
  container-type: inline-size;
  @include media(md) {
  padding-right: 70px;
  padding-left: 70px;
  }
  .top-under-inner {
    position: relative;
     @container (min-width: 992px) {
     display: flex;
    }
    .top-inner-inhos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      
      @container (min-width: 992px) {
        flex-flow: column;
        gap: 1rem;
        background-color: #fff;
        width: 24%;
        border-image-source: linear-gradient( #fff,#fff );
        border-image-slice: fill 0; 
        border-image-outset: 0 0 0 50vw;
        padding-top: 1rem;
        padding-right: 2rem;
      }
      .inhos-h {
        width: 50%;
        background-color: $white;
        @container (min-width: 992px) {
          width: 100%;
        }
        .inhos-h-wrapper {
          border-image-source: linear-gradient( #fff,#fff );
          border-image-slice: fill 0; 
          border-image-outset: 0 0 0 50vw;
          padding-top: 2rem;
          .inhos-h-text {
            display: flex;
            align-items: center;
            font-family: 'Shippori Mincho';
            font-weight: 700;
            font-size: 2rem;
            position: relative;
            padding-left: 1rem;
            @include media(md) {
              font-size: 2.6rem;
            }
            &::before {
              content: "";
              display: inline-block;
              height: 65%;
              width: 2px;
              background-color: #55824a;
              position: absolute;
              top: 50%;
              left: 0%;
              transform: translateY(-50%);
            }
          }
        }
        p {
          display: block;
          font-family: 'Times New Roman';
          font-size: 1rem;
          color: $gray-600;
          border-image-source: linear-gradient( #fff,#fff );
          border-image-slice: fill 0; 
          border-image-outset: 0 0 0 50vw;
          @container (min-width: 992px) {
            font-size: 1.3rem;
          }
        }
      }
      .inhos-space {
        width: 50vw;
        margin: calc(50% - 50vw);
        background-color: aqua;
        background-color: transparent;
        @container (min-width: 992px) {
          display: none;
        }
      }
      .inhos-btn-wrapper {
        width: 100%;
        background-color: $white;
        display: flex;
        gap: 1rem;
        border-image-source: linear-gradient( #fff,#fff );
        border-image-slice: fill 0; 
        border-image-outset: 0 100vw;
        @container (min-width: 992px) {
          flex-flow: column;
          padding-bottom: 2rem;
          height: 50%;
          justify-content:space-between;
        }
        .inhos-btn-inner {
          width: calc(50% - 0.5rem);
          padding-top: 2rem;
          @container (min-width: 992px) {
            width: 100%;
            padding-top: 0;
          }
          .inhos-btn {
            background-color: #55824a;
            border: $btn-border-width solid transparent;
            color: $white;
            cursor: pointer;
            display: inline-block;
            font-size: 1.4rem;
            font-weight: 400;
            text-align: center;
            appearance: none;
            vertical-align: middle;
            padding: $btn-y-padding-normal 1rem;
            border-radius: 5px;
            height: 100%;
            display: block;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            @container (min-width: 992px) {
              font-size: 1.5rem;
            }
            // hover
            @include hover(){
              background-color: #769b6e;
            }
          }
        }
      }
    }
    
    .top-inner-link {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      padding-top: 2rem;
      border-image-source: linear-gradient( #fff,#fff );
      border-image-slice: fill 0; 
      border-image-outset: 0 100vw;
      @container (min-width: 992px) {
        padding: 0;
        width: 76%;
        border-image-source: initial;
      }
      .link-trans {
        display: none;
        @container (min-width: 992px) {
          display: block;
          width: 100%;
          height: 3rem;
        }
      }
      .square-btn-wrapper {
        width: 100%;
        padding-top: 2rem;
        border-top: 1px solid #ced4da;
        overflow: hidden;
         @container (min-width: 852px) {
          display: flex;
          background-color: #fff;
          border-image-source: linear-gradient( #fff,#fff );
          border-image-slice: fill 0; 
          border-image-outset: 0 50vw 0 0;
          border-top: initial;
          width: 100%;
          //margin: -.5rem;
         }
        
        .square-btn-inner {
          display: flex;
          margin: -1rem;
          position: relative;
          //padding-bottom: .5rem;
          @container (min-width: 852px) {
            width: 100%;
            margin: initial;
            //padding: .5rem;
          }
          &:first-child {
            &::after {
              content: '';
              height: 1px;
              width: 100%;
              background-color: $gray-400;
              display: inline-block;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              @container (min-width: 852px) {
                content: none;
              }
            }
          }
          &:last-child {
            padding-top: .9rem;
            @container (min-width: 852px) {
              //padding-top: .5rem;
              padding-top: 0;
            }
            
          }
          
          .btn-item-wrapper {
            width: 50%;
            padding: 1rem;
            position: relative;
            @container (min-width: 852px) {
              padding: 0 1rem;
            }
            &:nth-child(2) {
               &::before {
                 content: '';
                 display: inline-block;
                 width: 1px;
                 height: 95%;
                 background-color: $gray-400;
                 position: absolute;
                 top: 50%;
                 left: -0.2%;
                 transform: translateY(-47%);
                  @include media(lg) {
                    content: none;
                  }
               }
            }
            &.num02 {
              &:nth-child(2) {
                 &::before {
                   content: '';
                   display: inline-block;
                   width: 1px;
                   height: 95%;
                   background-color: $gray-400;
                   position: absolute;
                   top: 50%;
                   left: -0.2%;
                   transform: translateY(-53%);
                    @include media(lg) {
                      content: none;
                    }
                 }
                 @container (min-width: 852px) {
                  &::after {
                    content: '';
                     display: inline-block;
                     width: 1px;
                     height: 100%;
                     background-color: $gray-400;
                     position: absolute;
                     top: 50%;
                     right: 0;
                     transform: translateY(-50%);
                  }
                }
              }
            }
            .square-btn-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              min-height: 180px;
              @container (min-width: 852px) {
                &::before {
                  content: '';
                   display: inline-block;
                   width: 1px;
                   height: 100%;
                   background-color: $gray-400;
                   position: absolute;
                   top: 50%;
                   left: 0;
                   transform: translateY(-50%);
                }
              }
              .square-btn-link {
                display: inline-block;
                width: 100%;
                height: 100%;
                background-color: #F5F7F5;
                border-radius: 5px;
                padding: 2.5rem 1rem 1rem;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                // hover
                @include hover(){
                  background-color: #DFF2DA;
                }
                .square-btn-icon {
                  margin: 0 auto;
                  position: relative;
                  z-index: 0;
                  &.num01 {
                    width: 35px;
                  }
                  &.num02 {
                    width: 70px;
                  }
                  &.num03 {
                    width: 70px;
                  }
                  &.num04 {
                    width: 70px;
                  }
                  &::after {
                    content: "";
                    display: inline-block;
                    background-image: url("../../../images/top/square-btn/bg-circle.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 8.4rem;
                    height: 8.4rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                  }
                  img {
                    border-style: none;
                    display: block;
                    height: 8rem;
                    width: 100%;
                  }
                }
                .square-btn-text {
                  font-size: 1.3rem;
                  color: #333;
                  margin-top: 1.5rem;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-grow: 1;
                  @container (min-width: 360px) {
                    font-size: 1.4rem;
                  }
                  @container (min-width: 414px) {
                    font-size: 1.6rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* TOPビジュアル下のボタン 小 */
.top-sm-link {
  padding-top: 2.5rem;
  container-type: inline-size;
  .top-smlink-inner {
      display: flex;
      flex-flow: column;
      gap: 1rem;
      padding-top: 2.5rem;
      border-top: 1px solid $gray-400;
      @container (min-width: 500px) {
        flex-flow:row;
        flex-wrap: wrap;
      }
    .link-item {
      width: 100%;
      @container (min-width: 500px) {
        width: calc(50% - 0.5rem);
      }
      /*@container (min-width: 865px) {
         width: calc(33.33333% - 0.666666rem);
      }*/
      @container (min-width: 1014px) {
        width: calc(25% - 0.75rem);
      }
      .link-item-btn {
        width: 100%;
        height: 100%;
        padding: 2rem 2rem;
        border: solid 1px $gray-400;
        border-radius: 5px;
        color: #333;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        @include media(md) {
          font-size: 1.5rem;
        }
        @include hover(){
           background-color: #F5F7F5;
        }
        
        .item-btn-icon {
          position: relative;
          z-index: 0;
          width: 4rem;
          height: 4rem;
          img {
            border-style: none;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &.num01 {
            img {
              width: 65%;
            }
          }
          &.num02 {
            img {
              width: 50%;
            }
          }
          &.num03 {
            img {
              width: 65%;
            }
          }
          &.num04 {
            img {
              width: 60%;
            }
          }
          &::after {
            content: "";
            display: inline-block;
            background-image: url("../../../images/top/square-btn/bg-circle.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 5rem;
            height: 5rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
        }
        .item-btn-text {
          padding-left: 2rem;
          font-size: 1.5rem;
          @container (min-width: 414px) {
            font-size: 1.6rem;
          }
          .item-btn-br {
            display: none;
            @container (min-width: 1304px) {
              display: inline;
            }
          }
        }
      }
    }
  }
}

/* スライダーメニュー */
.slidermenu-container {
  width: 100vw;
  margin: 0 calc(50% - 50vw);
  .slidermenu-swiper {
    .swiper-wrapper {
      transition-timing-function: linear;
      height: auto;
      align-items: flex-start;
      .swiper-slide {
        position: relative;
        width: 330px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include hover(){
          opacity: .5;
        }
        img {
          height: 100%;
          width: 100%;
        }
        p {
          position: absolute;
          bottom: 8%;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          color: #fff;
          font-size: 1.4rem;
          white-space: nowrap;
          background-color: #55824a;
          padding: 0.2rem 1rem;
        }
      }
    }
    .swiper-button-prev, .swiper-button-next {
      display: none;
      @include media(md) {
        display: block;
        background-color: $white;
        border-radius: 50%;
        color: $white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        @include hover(){
          background-color: $base;
          &.swiper-button-prev::after,&.swiper-button-next::after {
            background-image: set-svg($chevron-up, $white);
          }
        }
      }
    }
    .swiper-button-prev::after, .swiper-button-next::after, {
      content: '';
      background-image: set-svg($chevron-up, $base);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 20px;
      height: 20px;
    }
    .swiper-button-prev::after {
      transform: rotate(270deg);
    }
    .swiper-button-next::after {
      transform: rotate(90deg);
    }
  }
}

/* 重要なお知らせ */
.caution-container {
  display: flex;
  flex-direction: column;
  background-color: #FFF4F4;
  padding: 2.5rem 2rem;
  align-items: flex-start;
  @container (min-width: 730px) {
    flex-direction: row;
  }
  .caution-title {
    color: #333;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    transition: background-color var(--base-transition), color var(--base-transition);
    @container (min-width: 730px) {
      width: 25%;
    }
    &::before {
      content: "";
      height: 2.2rem;
      width: 2.2em;
      background-image: set-svg($caution, #333);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .caution-news-list {
    display: table;
    @container (min-width: 730px) {
      width: 75%;
    }
      .caution-news-container {
        margin-top: 2.3rem;
        @container (min-width: 730px) {
          &:first-child {
            margin-top: 0;
          }
        }
        .date{
          font-size: 1.6rem;
          color: #ff0000;
          padding-bottom: 0.4rem;
          display: flex;
          align-items: center;
          color: rgba(255,0,0, 0.5);
          @container (min-width: 730px) {
            font-size: 1.6rem;
          }
        }
        .category {
          display: none;
        }
        .title {
          display: inline;
          font-size: 1.6rem;
          a {
            color: #ff0000;
            @include hover(){
              opacity: .5;
            }
          }
          a[target="_blank"]:not(a[href$=".pdf"]){
            //display: inline-flex;
            //align-items: center;
            position: relative;
            &::after {
              content: '';
              background-image: set-svg($open-new-window, #0e67b5);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              display: inline-block;
              width: 1.8rem;
              height: 1.8rem;
              min-width: 1.8rem;
              min-height: 1.8rem;
              position: relative;
              margin-left: 0.5rem;
              transition: opacity #55824a-transition;
              vertical-align: middle;
            }
          }
          a[href$=".pdf"] {
            //display: inline-flex;
            //align-items: center;
            position: relative;
            &::after {
              content: '';
              background-image: set-svg($pdf, #ff0000);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              display: inline-block;
              width: 1.8rem;
              height: 1.8rem;
              min-width: 1.8rem;
              min-height: 1.8rem;
              position: relative;
              margin-left: 0.5rem;
              transition: opacity #55824a-transition;
              vertical-align: middle;
              
            }
          }
        }
      }
      @container (min-width: 730px) {
        .caution-news-container {
          display: table-row;
          margin-top: 0;
          &:first-child {
            .date, .title {
              padding-top: 0;
             }
          }
          &:last-child {
            .date, .title {
              padding-bottom: 0;
             }
          }
          .date, .title {
            display: table-cell;
            padding-top: .6rem;
            padding-bottom: .6rem;
           }
          .date {
             display: flex;
             width: 115px;
             padding-right: 0.9rem;
             align-items: center;
            justify-content: center;
            &::after {
              border-top: 1px solid;
              border-color: rgba(255,0,0, 0.5);
              content: "";
              width: 100%;
              max-width: 17px;
              margin-left: 10px;
            }
           }
          .title {
            width: 100%;
          }
        }
      }
      @container (min-width: 922px) {
        .caution-news-container {
          .date {
            width: 130px;
          }
        }
      }
  }
}


/* 在宅サービスについて */
.athome-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  .athome-item {
    width: calc(50% - 1rem);
    position: relative;
    border-bottom: solid 1px $gray-400;
    @container (min-width: 760px) {
       width: calc(25% - 1.5rem);
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 16px;
      border-bottom: solid 1px #55824a;
    }
    a {
      height: 100%;;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      p {
        font-size: 1.3rem;
        padding: 1.6rem 0;
        display: flex;
        align-items: flex-start;
        color: #333;
        flex-grow: 1;
        @container (min-width: 760px) {
          font-size: 1.4rem;
        }
        &::before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          min-width: 16px;
          min-height: 16px;
          margin: .4rem .4rem 0 0;
          background-image: url("../../../images/common/athome-item-arrow.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
    @include hover(){
      p {
        color: #55824a;
      }
      &::after {
        animation: border_anim .8s linear forwards;
      }
      @keyframes border_anim {
        0%{
          width: 10%;
        }
        100%{
          width: 100%;
        }
      }
    }
  }
}

/* お知らせ共通 */
.info-container {
  display: flex;
  flex-direction: column;
  @container (min-width: 810px) {
    flex-direction: row;
    gap: 6rem;
  }
  .into-inner {
    @container (min-width: 810px) {
      width: calc(50% - 3rem);
    }
    &.event {
      margin-top: 3rem;
      @container (min-width: 810px) {
        margin-top: 0;
        //margin-left: 6rem;
      }
    }
    .into-btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      .into-btn {
        display: inline-block;
        font-size: 1.5rem;
        border: solid 1px $gray-400;
        border-radius: 10px;
        padding: .8rem 6rem;
        margin-top: 2.5rem;
        @include hover() {
          background-color: #F5F7F5;
        }
      }
    }
  }
}

.info-news-list, .initiatives-events-list {
  .news-container {
    padding: 2rem 0 2rem .8rem;
    border-top: solid $gray-400 1px;
    transition: background-color var(--base-transition), color var(--base-transition);
    @include hover(){
      background-color: #F5F7F5;
    }
    &:last-child {
      border-bottom: solid $gray-400 1px;
    }
  }
}

/* お知らせ欄 */
.info-news-list {
  .news-container {
    .news-anchor {
      overflow: hidden;
      .date {
        display: inline-block;
        font-size: 1.3rem;
        opacity: .5;
        color: $gray-900;
      }
      .news-category {
        display: inline-block;
        font-size: 1rem;
        margin-left: 1rem;

        .category-item {
          background-color: #ddf1d7;
          padding: .3rem .5rem;
          color: #333;
        }
      }
      .title {
        font-size: 1.5rem;
        margin-top: 1rem;
        color: #333;
        @container (min-width: 600px) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    a[target="_blank"]:not(a[href$=".pdf"]){
      .title {
      align-items: center;
      position: relative;
      &::after {
        content: '';
        background-image: set-svg($open-new-window, #0e67b5);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        min-width: 1.8rem;
        min-height: 1.8rem;
        position: relative;
        margin-left: 0.5rem;
        transition: opacity #55824a-transition;
        vertical-align: middle;
      }
      }
    }
    a[href$=".pdf"] {
      .title {
        align-items: center;
        position: relative;
        &::after {
          content: '';
          background-image: set-svg($pdf, #ff0000);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          width: 1.8rem;
          height: 1.8rem;
          min-width: 1.8rem;
          min-height: 1.8rem;
          position: relative;
          margin-left: 0.5rem;
          transition: opacity #55824a-transition;
          vertical-align: middle;
        }
      }
    }
  }
}

/* 取り組み・イベント欄 */
.initiatives-events-list {
  .news-container {
    display: flex;
    .eyecatch {
      width: auto;
      min-width: 97px;
      height: 65.5px;
      margin-right: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @container (min-width: 600px) {
        margin: 0 2rem 0 0;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .news-contents {
      @container (min-width: 600px) {
        flex: 1;
        overflow: hidden;
      }
      .date {
        display: inline-block;
        font-size: 1.4rem;
        opacity: .5;
        color: $gray-900;
      }
      .title {
        font-size: 1.4rem;
        margin-top: 1rem;
        color: #333;
        overflow: hidden;
        a {
          display: block;
          width: 100%;
          @container (min-width: 600px) {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

#events {
.initiatives-events-list {
  .news-container {
    @media (min-width: 600px) {
      flex-direction: row;
    }
    .eyecatch {
      @media (min-width: 600px) {
        //width: 25%;
        //height: auto;
        //max-width: 180px;
        margin: auto 2rem auto 0;
        min-width: 120px;
        height: 80px;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .news-contents {
      @media (min-width: 600px) {
        width: 80%;
      }
    }
  }
}
}


/* 採用情報 */
.toprec-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  .toprec-item {
    width: calc(50% - 0.5rem);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    @container (min-width: 760px) {
       width: calc(25% - 0.75rem);
    }
    @include hover(){
      p {
        background-color: #55824a;
      }
    }
    a {
      display: block;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      img {
        display: block;
        border-radius: 8px 8px 0 0;
      }
      p {
        font-size: 1.3rem;
        padding: 1.6rem;
        display: flex;
        align-items: flex-start;
        color: #fff;
        background-color: #245e42;
        border-radius: 0 0 8px 8px;
        flex-grow: 1;
        &::before {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          min-width: 18px;
          min-height: 18px;
          background-image: url("../../../images/common/top-rec-arrow.png");
          background-repeat: no-repeat;
          background-size: contain;
          margin: .3rem .5rem 0 0;
        }
      }
    }
  }
}

/* その他（広報誌、ブログなど） */
.others-container {
  display: flex;
  flex-flow: column;
  gap: 3.5rem;
  @include media(lg) {
    flex-flow: row;
  }
  .others-item {
     width: 100%;
    a {
      display: flex;
      @include hover(){
        p {
          color: #55824a;
        }
    }
      div {
        display: block;
        width: 23%;
        height: fit-content;
        aspect-ratio: 1 / 1;
        background-size: cover;
        overflow: hidden;
        margin-right: 1.4rem;
        min-width: 77px;
        max-width: 100px;
      }
      p {
        width: 70%;
        font-size: 1.3rem;
        color: #333;
        span {
          display: block;
          font-size: 1.5rem;
          margin-bottom: .5rem;
          text-decoration: underline;
        }
      }
    }
  }
}

//見出し
.top-h {
  font-family: 'Shippori Mincho';
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1.6rem;
  padding-left: .8rem;
  display: flex;
  align-items: flex-end;
  position: relative;
  @include media(md) {
    font-size: 2.6rem;
    margin-bottom: 3.2rem;
  }
  &::before {
    content: "";
    display: inline-block;
    height: 65%;
    width: 2px;
    background-color: #55824a;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
  }

  span {
    font-family: 'Times New Roman';
    font-weight: initial;
    color: $gray-600;
    font-size: 1rem;
    margin-left: 1.5rem;
    @include media(md) {
      font-size: 1.3rem;
      margin-left: 2rem;
    }
  }
}


/*TOP 予防接種モーダル*/
/*モーダルを開くボタン*/
.modal-open:hover{
	cursor: pointer;
}
/*モーダル本体の指定 + モーダル外側の背景の指定*/
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0,0,0,50%);
  padding: 40px 20px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  box-sizing: border-box;
  z-index: 100;
}
/*モーダル本体の擬似要素の指定*/
.modal-container:before{
	content: "";
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}
/*モーダル本体に「active」クラス付与した時のスタイル*/
.modal-container.active{
	opacity: 1;
	visibility: visible;
}
/*モーダル枠の指定*/
.modal-body{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	max-width: 500px;
	width: 90%;
}
/*モーダルを閉じるボタンの指定*/
.modal-close{
	position: absolute;
	display: flex;
    align-items: center;
    justify-content: center;
	top: -40px;
	right: -40px;
	width: 40px;
	height: 40px;
	font-size: 40px;
	color: #fff;
	cursor: pointer;
}
/*モーダル内のコンテンツの指定*/
.modal-content{
	background: #fff;
	text-align: left;
	padding: 30px;
}

.menkai-btn {
  width: 100%;
  padding: 2rem;
  background-color: #55824a;
  border-radius: 5px;
  font-size: 1.5rem;
  a {
    color: #fff;
    .txt {
      &::after {
        content: "";
        background-image: set-svg($pdf, #fff);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        min-width: 1.8rem;
        min-height: 1.8rem;
        margin-left: .5rem;
        vertical-align: middle;
      }
    }
  }
}

.construct-container:has(.modal-container.active){
  overflow: hidden;
}