@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300&display=swap");
@font-face {
  font-family: Material Icons Outlined;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v108/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format("woff2");
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-text-opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#top .construct-main {
  padding: initial !important;
  margin-top: 64px;
}
@media screen and (min-width: 992px) {
  #top .construct-main {
    margin-top: 129px;
  }
}

.top-section-wrapper, .top-sm-link {
  padding-left: 5%;
  padding-right: 5%;
}
@media screen and (min-width: 768px) {
  .top-section-wrapper, .top-sm-link {
    padding-left: 70px;
    padding-right: 70px;
  }
}

.top-section-wrapper {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
  background-color: #fff;
}
.top-section-wrapper.top-bg-green {
  background-color: #F4F7F5;
}
.top-section-wrapper.caution {
  padding-top: 0;
}

.top-inner-container, .top-under-inner, .top-smlink-inner {
  max-width: 1340px;
  margin: 0 auto;
  container-type: inline-size;
}

/*ホバー時のふわっ*/
a {
  text-decoration: none;
  transition: background-color var(--base-transition), color var(--base-transition);
}

/* TOPビジュアル */
/*.top-visual-wrapper {
  position: relative;
}*/
.top-visual-inner {
  height: 67vh;
  min-height: 570px;
  position: relative;
}
.top-visual-inner .top-slide {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.top-visual-inner .top-slide .top-visual-swiper {
  height: 100%;
}
.top-visual-inner .top-slide .top-visual-swiper .swiper-wrapper .swiper-slide .slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-visual-inner .top-slide .top-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 41, 37, 0.15);
  z-index: 1;
}
.top-visual-inner .top-slide .top-slide-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media screen and (min-width: 992px) {
  .top-visual-inner .top-slide .top-slide-blur {
    width: 50%;
  }
}
.top-visual-inner .top-slide .top-slide-blur .top-blur-img {
  width: 100%;
  height: 100%;
}
.top-visual-inner .top-slide .top-slide-h {
  color: #fff;
  position: absolute;
  top: 60%;
  left: 0%;
  transform: translateY(-50%);
  padding-left: 5%;
  z-index: 1;
  text-shadow: 0px 0px 8px rgba(2, 26, 14, 0.2);
}
@media screen and (min-width: 768px) {
  .top-visual-inner .top-slide .top-slide-h {
    padding-left: 70px;
  }
}
@media screen and (min-width: 992px) {
  .top-visual-inner .top-slide .top-slide-h {
    top: 40%;
  }
}
.top-visual-inner .top-slide .top-slide-h .slide-h-main {
  font-family: "Shippori Mincho";
  font-weight: 300;
  font-size: 2.6rem;
  line-height: 1.5;
}
@media screen and (min-width: 992px) {
  .top-visual-inner .top-slide .top-slide-h .slide-h-main {
    font-size: 4.2rem;
    line-height: initial;
  }
}
.top-visual-inner .top-slide .top-slide-h .slide-h-sub {
  font-size: 1.5rem;
  margin-top: 2rem;
  line-height: 1.5;
}
@media screen and (min-width: 992px) {
  .top-visual-inner .top-slide .top-slide-h .slide-h-sub {
    font-size: 1.8rem;
    line-height: 1.8;
  }
}
.top-visual-inner .top-slide .top-slide-h .slide-h-guide {
  display: inline-block;
  color: #fff;
  margin-top: 2rem;
}
.top-visual-inner .top-slide .top-slide-h .slide-h-guide .slide-h-guide-text {
  font-size: 1.35rem;
  padding-bottom: 0.2rem;
  border-bottom: solid 1px #fff;
  display: inline-block;
}
@media screen and (min-width: 992px) {
  .top-visual-inner .top-slide .top-slide-h .slide-h-guide .slide-h-guide-text {
    font-size: 1.5rem;
  }
}
.top-visual-inner .top-slide .top-slide-h .slide-h-guide .slide-h-guide-arrow {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  line-height: 1;
  position: relative;
  width: 3rem;
  height: 3rem;
  border: 0.075em solid currentColor;
  background: currentColor;
  border-radius: 50%;
  box-sizing: content-box;
  margin-left: 1rem;
}
.top-visual-inner .top-slide .top-slide-h .slide-h-guide .slide-h-guide-arrow::after {
  content: "";
  color: #245e42;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 0.3em 0.5em;
  border-left-color: currentColor;
  border-right: 0;
  transform: translateX(15%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.top-slide .swiper-slide-active .slide-image,
.top-slide .swiper-slide-duplicate-active .slide-image,
.top-slide .swiper-slide-prev .slide-image {
  animation: zoomanime 5s linear 0s normal both;
}

@keyframes zoomanime {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/* TOP採用ボタン */
.top-rec-btn {
  width: calc(50% - 0.7rem);
  height: 65px;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: #245e42;
  border-radius: 5px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
}
@media screen and (min-width: 768px) {
  .top-rec-btn {
    flex-direction: column;
    justify-content: center;
    width: 100px;
    height: 100px;
    top: -30px;
  }
}
@media (min-width: 1132px) {
  .top-rec-btn {
    top: -80px;
    right: -50px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .top-rec-btn:hover {
    color: #fff;
    background-color: #2b704f;
  }
}
.top-rec-btn .top-rec-circle {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  line-height: 1;
  position: relative;
  width: 4rem;
  height: 4rem;
  border: 0.075em solid currentColor;
  background: currentColor;
  border-radius: 50%;
  margin: 0 1rem;
}
@media screen and (min-width: 992px) {
  .top-rec-btn .top-rec-circle {
    margin-bottom: 0.5rem;
  }
}
.top-rec-btn .top-rec-circle::before {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23245e42"%3e%3cpath d="M18,14v-3h-3V9h3V6h2v3h3v2h-3v3H18z M9,12c-1.1,0-2-0.4-2.8-1.2C5.4,10,5,9.1,5,8s0.4-2,1.2-2.8S7.9,4,9,4s2,0.4,2.8,1.2C12.6,6,13,6.9,13,8s-0.4,2-1.2,2.8C11,11.6,10.1,12,9,12z M1,20v-2.8c0-0.6,0.1-1.1,0.4-1.6c0.3-0.5,0.7-0.8,1.2-1.1c1-0.5,2.1-0.9,3.1-1.2S7.9,13,9,13s2.2,0.1,3.2,0.4s2.1,0.6,3.2,1.2c0.5,0.2,0.9,0.6,1.2,1.1c0.3,0.5,0.4,1,0.4,1.6V20H1z M3,18h12v-0.8c0-0.2,0-0.4-0.1-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.9-0.5-1.8-0.8-2.7-1C10.9,15.1,9.9,15,9,15s-1.9,0.1-2.8,0.3c-0.9,0.2-1.8,0.6-2.7,1c-0.1,0.1-0.3,0.2-0.4,0.4C3,16.9,3,17,3,17.2V18z M9,10c0.6,0,1-0.2,1.4-0.6C10.8,9,11,8.6,11,8s-0.2-1-0.6-1.4C10,6.2,9.6,6,9,6S8,6.2,7.6,6.6C7.2,7,7,7.4,7,8s0.2,1,0.6,1.4C8,9.8,8.4,10,9,10z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.5rem;
  height: 2.5rem;
}

/* TOPビジュアル下 */
.top-under-wrapper {
  width: 100%;
  margin-top: -10vh;
  padding-right: 5%;
  padding-left: 5%;
  container-type: inline-size;
}
@media screen and (min-width: 768px) {
  .top-under-wrapper {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.top-under-wrapper .top-under-inner {
  position: relative;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner {
    display: flex;
  }
}
.top-under-wrapper .top-under-inner .top-inner-inhos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-inhos {
    flex-flow: column;
    gap: 1rem;
    background-color: #fff;
    width: 24%;
    border-image-source: linear-gradient(#fff, #fff);
    border-image-slice: fill 0;
    border-image-outset: 0 0 0 50vw;
    padding-top: 1rem;
    padding-right: 2rem;
  }
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-h {
  width: 50%;
  background-color: #fff;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-inhos .inhos-h {
    width: 100%;
  }
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-h .inhos-h-wrapper {
  border-image-source: linear-gradient(#fff, #fff);
  border-image-slice: fill 0;
  border-image-outset: 0 0 0 50vw;
  padding-top: 2rem;
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-h .inhos-h-wrapper .inhos-h-text {
  display: flex;
  align-items: center;
  font-family: "Shippori Mincho";
  font-weight: 700;
  font-size: 2rem;
  position: relative;
  padding-left: 1rem;
}
@media screen and (min-width: 768px) {
  .top-under-wrapper .top-under-inner .top-inner-inhos .inhos-h .inhos-h-wrapper .inhos-h-text {
    font-size: 2.6rem;
  }
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-h .inhos-h-wrapper .inhos-h-text::before {
  content: "";
  display: inline-block;
  height: 65%;
  width: 2px;
  background-color: #55824a;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-h p {
  display: block;
  font-family: "Times New Roman";
  font-size: 1rem;
  color: #6c757d;
  border-image-source: linear-gradient(#fff, #fff);
  border-image-slice: fill 0;
  border-image-outset: 0 0 0 50vw;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-inhos .inhos-h p {
    font-size: 1.3rem;
  }
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-space {
  width: 50vw;
  margin: calc(50% - 50vw);
  background-color: aqua;
  background-color: transparent;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-inhos .inhos-space {
    display: none;
  }
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-btn-wrapper {
  width: 100%;
  background-color: #fff;
  display: flex;
  gap: 1rem;
  border-image-source: linear-gradient(#fff, #fff);
  border-image-slice: fill 0;
  border-image-outset: 0 100vw;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-inhos .inhos-btn-wrapper {
    flex-flow: column;
    padding-bottom: 2rem;
    height: 50%;
    justify-content: space-between;
  }
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-btn-wrapper .inhos-btn-inner {
  width: calc(50% - 0.5rem);
  padding-top: 2rem;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-inhos .inhos-btn-wrapper .inhos-btn-inner {
    width: 100%;
    padding-top: 0;
  }
}
.top-under-wrapper .top-under-inner .top-inner-inhos .inhos-btn-wrapper .inhos-btn-inner .inhos-btn {
  background-color: #55824a;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  appearance: none;
  vertical-align: middle;
  padding: 0.625em 1rem;
  border-radius: 5px;
  height: 100%;
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-inhos .inhos-btn-wrapper .inhos-btn-inner .inhos-btn {
    font-size: 1.5rem;
  }
}
@media (hover: hover) and (pointer: fine) {
  .top-under-wrapper .top-under-inner .top-inner-inhos .inhos-btn-wrapper .inhos-btn-inner .inhos-btn:hover {
    background-color: #769b6e;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 2rem;
  border-image-source: linear-gradient(#fff, #fff);
  border-image-slice: fill 0;
  border-image-outset: 0 100vw;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-link {
    padding: 0;
    width: 76%;
    border-image-source: initial;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .link-trans {
  display: none;
}
@container (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-link .link-trans {
    display: block;
    width: 100%;
    height: 3rem;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper {
  width: 100%;
  padding-top: 2rem;
  border-top: 1px solid #ced4da;
  overflow: hidden;
}
@container (min-width: 852px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper {
    display: flex;
    background-color: #fff;
    border-image-source: linear-gradient(#fff, #fff);
    border-image-slice: fill 0;
    border-image-outset: 0 50vw 0 0;
    border-top: initial;
    width: 100%;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner {
  display: flex;
  margin: -1rem;
  position: relative;
}
@container (min-width: 852px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner {
    width: 100%;
    margin: initial;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner:first-child::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ced4da;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@container (min-width: 852px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner:first-child::after {
    content: none;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner:last-child {
  padding-top: 0.9rem;
}
@container (min-width: 852px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner:last-child {
    padding-top: 0;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper {
  width: 50%;
  padding: 1rem;
  position: relative;
}
@container (min-width: 852px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper {
    padding: 0 1rem;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper:nth-child(2)::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 95%;
  background-color: #ced4da;
  position: absolute;
  top: 50%;
  left: -0.2%;
  transform: translateY(-47%);
}
@media screen and (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper:nth-child(2)::before {
    content: none;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper.num02:nth-child(2)::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 95%;
  background-color: #ced4da;
  position: absolute;
  top: 50%;
  left: -0.2%;
  transform: translateY(-53%);
}
@media screen and (min-width: 992px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper.num02:nth-child(2)::before {
    content: none;
  }
}
@container (min-width: 852px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper.num02:nth-child(2)::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 100%;
    background-color: #ced4da;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 180px;
}
@container (min-width: 852px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 100%;
    background-color: #ced4da;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #F5F7F5;
  border-radius: 5px;
  padding: 2.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (hover: hover) and (pointer: fine) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link:hover {
    background-color: #DFF2DA;
  }
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-icon {
  margin: 0 auto;
  position: relative;
  z-index: 0;
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-icon.num01 {
  width: 35px;
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-icon.num02 {
  width: 70px;
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-icon.num03 {
  width: 70px;
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-icon.num04 {
  width: 70px;
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-icon::after {
  content: "";
  display: inline-block;
  background-image: url("../../../images/top/square-btn/bg-circle.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 8.4rem;
  height: 8.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-icon img {
  border-style: none;
  display: block;
  height: 8rem;
  width: 100%;
}
.top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-text {
  font-size: 1.3rem;
  color: #333;
  margin-top: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
@container (min-width: 360px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-text {
    font-size: 1.4rem;
  }
}
@container (min-width: 414px) {
  .top-under-wrapper .top-under-inner .top-inner-link .square-btn-wrapper .square-btn-inner .btn-item-wrapper .square-btn-item .square-btn-link .square-btn-text {
    font-size: 1.6rem;
  }
}

/* TOPビジュアル下のボタン 小 */
.top-sm-link {
  padding-top: 2.5rem;
  container-type: inline-size;
}
.top-sm-link .top-smlink-inner {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding-top: 2.5rem;
  border-top: 1px solid #ced4da;
}
@container (min-width: 500px) {
  .top-sm-link .top-smlink-inner {
    flex-flow: row;
    flex-wrap: wrap;
  }
}
.top-sm-link .top-smlink-inner .link-item {
  width: 100%;
  /*@container (min-width: 865px) {
     width: calc(33.33333% - 0.666666rem);
  }*/
}
@container (min-width: 500px) {
  .top-sm-link .top-smlink-inner .link-item {
    width: calc(50% - 0.5rem);
  }
}
@container (min-width: 1014px) {
  .top-sm-link .top-smlink-inner .link-item {
    width: calc(25% - 0.75rem);
  }
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn {
  width: 100%;
  height: 100%;
  padding: 2rem 2rem;
  border: solid 1px #ced4da;
  border-radius: 5px;
  color: #333;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .top-sm-link .top-smlink-inner .link-item .link-item-btn {
    font-size: 1.5rem;
  }
}
@media (hover: hover) and (pointer: fine) {
  .top-sm-link .top-smlink-inner .link-item .link-item-btn:hover {
    background-color: #F5F7F5;
  }
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-icon {
  position: relative;
  z-index: 0;
  width: 4rem;
  height: 4rem;
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-icon img {
  border-style: none;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-icon.num01 img {
  width: 65%;
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-icon.num02 img {
  width: 50%;
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-icon.num03 img {
  width: 65%;
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-icon.num04 img {
  width: 60%;
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-icon::after {
  content: "";
  display: inline-block;
  background-image: url("../../../images/top/square-btn/bg-circle.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-text {
  padding-left: 2rem;
  font-size: 1.5rem;
}
@container (min-width: 414px) {
  .top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-text {
    font-size: 1.6rem;
  }
}
.top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-text .item-btn-br {
  display: none;
}
@container (min-width: 1304px) {
  .top-sm-link .top-smlink-inner .link-item .link-item-btn .item-btn-text .item-btn-br {
    display: inline;
  }
}

/* スライダーメニュー */
.slidermenu-container {
  width: 100vw;
  margin: 0 calc(50% - 50vw);
}
.slidermenu-container .slidermenu-swiper .swiper-wrapper {
  transition-timing-function: linear;
  height: auto;
  align-items: flex-start;
}
.slidermenu-container .slidermenu-swiper .swiper-wrapper .swiper-slide {
  position: relative;
  width: 330px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (hover: hover) and (pointer: fine) {
  .slidermenu-container .slidermenu-swiper .swiper-wrapper .swiper-slide:hover {
    opacity: 0.5;
  }
}
.slidermenu-container .slidermenu-swiper .swiper-wrapper .swiper-slide img {
  height: 100%;
  width: 100%;
}
.slidermenu-container .slidermenu-swiper .swiper-wrapper .swiper-slide p {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  color: #fff;
  font-size: 1.4rem;
  white-space: nowrap;
  background-color: #55824a;
  padding: 0.2rem 1rem;
}
.slidermenu-container .slidermenu-swiper .swiper-button-prev, .slidermenu-container .slidermenu-swiper .swiper-button-next {
  display: none;
}
@media screen and (min-width: 768px) {
  .slidermenu-container .slidermenu-swiper .swiper-button-prev, .slidermenu-container .slidermenu-swiper .swiper-button-next {
    display: block;
    background-color: #fff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }
}
@media screen and (min-width: 768px) and (hover: hover) and (pointer: fine) {
  .slidermenu-container .slidermenu-swiper .swiper-button-prev:hover, .slidermenu-container .slidermenu-swiper .swiper-button-next:hover {
    background-color: #0B8660;
  }
  .slidermenu-container .slidermenu-swiper .swiper-button-prev:hover.swiper-button-prev::after, .slidermenu-container .slidermenu-swiper .swiper-button-prev:hover.swiper-button-next::after, .slidermenu-container .slidermenu-swiper .swiper-button-next:hover.swiper-button-prev::after, .slidermenu-container .slidermenu-swiper .swiper-button-next:hover.swiper-button-next::after {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M4.3,15.7c0.4,0.4,1,0.4,1.4,0L12,9.4l6.3,6.3c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-7-7c-0.4-0.4-1-0.4-1.4,0l-7,7C3.9,14.7,3.9,15.3,4.3,15.7z"/%3e%3c/svg%3e');
  }
}
.slidermenu-container .slidermenu-swiper .swiper-button-prev::after, .slidermenu-container .slidermenu-swiper .swiper-button-next::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230B8660"%3e%3cpath d="M4.3,15.7c0.4,0.4,1,0.4,1.4,0L12,9.4l6.3,6.3c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-7-7c-0.4-0.4-1-0.4-1.4,0l-7,7C3.9,14.7,3.9,15.3,4.3,15.7z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 20px;
  height: 20px;
}
.slidermenu-container .slidermenu-swiper .swiper-button-prev::after {
  transform: rotate(270deg);
}
.slidermenu-container .slidermenu-swiper .swiper-button-next::after {
  transform: rotate(90deg);
}

/* 重要なお知らせ */
.caution-container {
  display: flex;
  flex-direction: column;
  background-color: #FFF4F4;
  padding: 2.5rem 2rem;
  align-items: flex-start;
}
@container (min-width: 730px) {
  .caution-container {
    flex-direction: row;
  }
}
.caution-container .caution-title {
  color: #333;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  transition: background-color var(--base-transition), color var(--base-transition);
}
@container (min-width: 730px) {
  .caution-container .caution-title {
    width: 25%;
  }
}
.caution-container .caution-title::before {
  content: "";
  height: 2.2rem;
  width: 2.2em;
  background-image: url('data:image/svg+xml,%3csvg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" fill="%23333"%3e%3cpath d="M12,17c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C12.5,15.1,12.3,15,12,15s-0.5,0.1-0.7,0.3C11.1,15.5,11,15.7,11,16c0,0.3,0.1,0.5,0.3,0.7C11.5,16.9,11.7,17,12,17z M11,13h2V7h-2V13z M12,22c-1.4,0-2.7-0.3-3.9-0.8S5.8,20,4.9,19.1c-0.9-0.9-1.6-2-2.1-3.2S2,13.4,2,12s0.3-2.7,0.8-3.9S4,5.8,4.9,4.9s2-1.6,3.2-2.1S10.6,2,12,2s2.7,0.3,3.9,0.8s2.3,1.2,3.2,2.1s1.6,2,2.1,3.2C21.7,9.3,22,10.6,22,12s-0.3,2.7-0.8,3.9c-0.5,1.2-1.2,2.3-2.1,3.2c-0.9,0.9-2,1.6-3.2,2.1S13.4,22,12,22z M12,20c2.2,0,4.1-0.8,5.7-2.3c1.5-1.5,2.3-3.4,2.3-5.7s-0.8-4.1-2.3-5.7C16.1,4.8,14.2,4,12,4S7.9,4.8,6.3,6.3S4,9.8,4,12s0.8,4.1,2.3,5.7C7.9,19.2,9.8,20,12,20z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.caution-container .caution-news-list {
  display: table;
}
@container (min-width: 730px) {
  .caution-container .caution-news-list {
    width: 75%;
  }
}
.caution-container .caution-news-list .caution-news-container {
  margin-top: 2.3rem;
}
@container (min-width: 730px) {
  .caution-container .caution-news-list .caution-news-container:first-child {
    margin-top: 0;
  }
}
.caution-container .caution-news-list .caution-news-container .date {
  font-size: 1.6rem;
  color: #ff0000;
  padding-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: rgba(255, 0, 0, 0.5);
}
@container (min-width: 730px) {
  .caution-container .caution-news-list .caution-news-container .date {
    font-size: 1.6rem;
  }
}
.caution-container .caution-news-list .caution-news-container .category {
  display: none;
}
.caution-container .caution-news-list .caution-news-container .title {
  display: inline;
  font-size: 1.6rem;
}
.caution-container .caution-news-list .caution-news-container .title a {
  color: #ff0000;
}
@media (hover: hover) and (pointer: fine) {
  .caution-container .caution-news-list .caution-news-container .title a:hover {
    opacity: 0.5;
  }
}
.caution-container .caution-news-list .caution-news-container .title a[target=_blank]:not(a[href$=".pdf"]) {
  position: relative;
}
.caution-container .caution-news-list .caution-news-container .title a[target=_blank]:not(a[href$=".pdf"])::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230e67b5"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  position: relative;
  margin-left: 0.5rem;
  transition: opacity #55824a -transition;
  vertical-align: middle;
}
.caution-container .caution-news-list .caution-news-container .title a[href$=".pdf"] {
  position: relative;
}
.caution-container .caution-news-list .caution-news-container .title a[href$=".pdf"]::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0000"%3e%3cpath d="M9,12.5h1v-2h1c0.3,0,0.5-0.1,0.7-0.3C11.9,10,12,9.8,12,9.5v-1c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3H9V12.5zM10,9.5v-1h1v1H10z M13,12.5h2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-2V12.5z M14,11.5v-3h1v3H14z M17,12.5h1v-2h1v-1h-1v-1h1v-1h-2V12.5z M8,18c-0.5,0-1-0.2-1.4-0.6C6.2,17,6,16.5,6,16V4c0-0.5,0.2-1,0.6-1.4C7,2.2,7.5,2,8,2h12c0.6,0,1,0.2,1.4,0.6S22,3.4,22,4v12c0,0.5-0.2,1-0.6,1.4S20.6,18,20,18H8z M8,16h12V4H8V16z M4,22c-0.5,0-1-0.2-1.4-0.6S2,20.5,2,20V6h2v14h14v2H4z M8,4v12V4z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  position: relative;
  margin-left: 0.5rem;
  transition: opacity #55824a -transition;
  vertical-align: middle;
}
@container (min-width: 730px) {
  .caution-container .caution-news-list .caution-news-container {
    display: table-row;
    margin-top: 0;
  }
  .caution-container .caution-news-list .caution-news-container:first-child .date, .caution-container .caution-news-list .caution-news-container:first-child .title {
    padding-top: 0;
  }
  .caution-container .caution-news-list .caution-news-container:last-child .date, .caution-container .caution-news-list .caution-news-container:last-child .title {
    padding-bottom: 0;
  }
  .caution-container .caution-news-list .caution-news-container .date, .caution-container .caution-news-list .caution-news-container .title {
    display: table-cell;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .caution-container .caution-news-list .caution-news-container .date {
    display: flex;
    width: 115px;
    padding-right: 0.9rem;
    align-items: center;
    justify-content: center;
  }
  .caution-container .caution-news-list .caution-news-container .date::after {
    border-top: 1px solid;
    border-color: rgba(255, 0, 0, 0.5);
    content: "";
    width: 100%;
    max-width: 17px;
    margin-left: 10px;
  }
  .caution-container .caution-news-list .caution-news-container .title {
    width: 100%;
  }
}
@container (min-width: 922px) {
  .caution-container .caution-news-list .caution-news-container .date {
    width: 130px;
  }
}

/* 在宅サービスについて */
.athome-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.athome-container .athome-item {
  width: calc(50% - 1rem);
  position: relative;
  border-bottom: solid 1px #ced4da;
}
@container (min-width: 760px) {
  .athome-container .athome-item {
    width: calc(25% - 1.5rem);
  }
}
.athome-container .athome-item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 16px;
  border-bottom: solid 1px #55824a;
}
.athome-container .athome-item a {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.athome-container .athome-item a p {
  font-size: 1.3rem;
  padding: 1.6rem 0;
  display: flex;
  align-items: flex-start;
  color: #333;
  flex-grow: 1;
}
@container (min-width: 760px) {
  .athome-container .athome-item a p {
    font-size: 1.4rem;
  }
}
.athome-container .athome-item a p::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin: 0.4rem 0.4rem 0 0;
  background-image: url("../../../images/common/athome-item-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (hover: hover) and (pointer: fine) {
  .athome-container .athome-item:hover p {
    color: #55824a;
  }
  .athome-container .athome-item:hover::after {
    animation: border_anim 0.8s linear forwards;
  }
  @keyframes border_anim {
    0% {
      width: 10%;
    }
    100% {
      width: 100%;
    }
  }
}

/* お知らせ共通 */
.info-container {
  display: flex;
  flex-direction: column;
}
@container (min-width: 810px) {
  .info-container {
    flex-direction: row;
    gap: 6rem;
  }
}
@container (min-width: 810px) {
  .info-container .into-inner {
    width: calc(50% - 3rem);
  }
}
.info-container .into-inner.event {
  margin-top: 3rem;
}
@container (min-width: 810px) {
  .info-container .into-inner.event {
    margin-top: 0;
  }
}
.info-container .into-inner .into-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.info-container .into-inner .into-btn-wrapper .into-btn {
  display: inline-block;
  font-size: 1.5rem;
  border: solid 1px #ced4da;
  border-radius: 10px;
  padding: 0.8rem 6rem;
  margin-top: 2.5rem;
}
@media (hover: hover) and (pointer: fine) {
  .info-container .into-inner .into-btn-wrapper .into-btn:hover {
    background-color: #F5F7F5;
  }
}

.info-news-list .news-container, .initiatives-events-list .news-container {
  padding: 2rem 0 2rem 0.8rem;
  border-top: solid #ced4da 1px;
  transition: background-color var(--base-transition), color var(--base-transition);
}
@media (hover: hover) and (pointer: fine) {
  .info-news-list .news-container:hover, .initiatives-events-list .news-container:hover {
    background-color: #F5F7F5;
  }
}
.info-news-list .news-container:last-child, .initiatives-events-list .news-container:last-child {
  border-bottom: solid #ced4da 1px;
}

/* お知らせ欄 */
.info-news-list .news-container .news-anchor {
  overflow: hidden;
}
.info-news-list .news-container .news-anchor .date {
  display: inline-block;
  font-size: 1.3rem;
  opacity: 0.5;
  color: #212529;
}
.info-news-list .news-container .news-anchor .news-category {
  display: inline-block;
  font-size: 1rem;
  margin-left: 1rem;
}
.info-news-list .news-container .news-anchor .news-category .category-item {
  background-color: #ddf1d7;
  padding: 0.3rem 0.5rem;
  color: #333;
}
.info-news-list .news-container .news-anchor .title {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #333;
}
@container (min-width: 600px) {
  .info-news-list .news-container .news-anchor .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.info-news-list .news-container a[target=_blank]:not(a[href$=".pdf"]) .title {
  align-items: center;
  position: relative;
}
.info-news-list .news-container a[target=_blank]:not(a[href$=".pdf"]) .title::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%230e67b5"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  position: relative;
  margin-left: 0.5rem;
  transition: opacity #55824a -transition;
  vertical-align: middle;
}
.info-news-list .news-container a[href$=".pdf"] .title {
  align-items: center;
  position: relative;
}
.info-news-list .news-container a[href$=".pdf"] .title::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0000"%3e%3cpath d="M9,12.5h1v-2h1c0.3,0,0.5-0.1,0.7-0.3C11.9,10,12,9.8,12,9.5v-1c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3H9V12.5zM10,9.5v-1h1v1H10z M13,12.5h2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-2V12.5z M14,11.5v-3h1v3H14z M17,12.5h1v-2h1v-1h-1v-1h1v-1h-2V12.5z M8,18c-0.5,0-1-0.2-1.4-0.6C6.2,17,6,16.5,6,16V4c0-0.5,0.2-1,0.6-1.4C7,2.2,7.5,2,8,2h12c0.6,0,1,0.2,1.4,0.6S22,3.4,22,4v12c0,0.5-0.2,1-0.6,1.4S20.6,18,20,18H8z M8,16h12V4H8V16z M4,22c-0.5,0-1-0.2-1.4-0.6S2,20.5,2,20V6h2v14h14v2H4z M8,4v12V4z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  position: relative;
  margin-left: 0.5rem;
  transition: opacity #55824a -transition;
  vertical-align: middle;
}

/* 取り組み・イベント欄 */
.initiatives-events-list .news-container {
  display: flex;
}
.initiatives-events-list .news-container .eyecatch {
  width: auto;
  min-width: 97px;
  height: 65.5px;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@container (min-width: 600px) {
  .initiatives-events-list .news-container .eyecatch {
    margin: 0 2rem 0 0;
  }
}
.initiatives-events-list .news-container .eyecatch img {
  width: 100%;
  object-fit: cover;
}
@container (min-width: 600px) {
  .initiatives-events-list .news-container .news-contents {
    flex: 1;
    overflow: hidden;
  }
}
.initiatives-events-list .news-container .news-contents .date {
  display: inline-block;
  font-size: 1.4rem;
  opacity: 0.5;
  color: #212529;
}
.initiatives-events-list .news-container .news-contents .title {
  font-size: 1.4rem;
  margin-top: 1rem;
  color: #333;
  overflow: hidden;
}
.initiatives-events-list .news-container .news-contents .title a {
  display: block;
  width: 100%;
}
@container (min-width: 600px) {
  .initiatives-events-list .news-container .news-contents .title a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (min-width: 600px) {
  #events .initiatives-events-list .news-container {
    flex-direction: row;
  }
}
@media (min-width: 600px) {
  #events .initiatives-events-list .news-container .eyecatch {
    margin: auto 2rem auto 0;
    min-width: 120px;
    height: 80px;
  }
}
#events .initiatives-events-list .news-container .eyecatch img {
  width: 100%;
  object-fit: cover;
}
@media (min-width: 600px) {
  #events .initiatives-events-list .news-container .news-contents {
    width: 80%;
  }
}

/* 採用情報 */
.toprec-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.toprec-container .toprec-item {
  width: calc(50% - 0.5rem);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
@container (min-width: 760px) {
  .toprec-container .toprec-item {
    width: calc(25% - 0.75rem);
  }
}
@media (hover: hover) and (pointer: fine) {
  .toprec-container .toprec-item:hover p {
    background-color: #55824a;
  }
}
.toprec-container .toprec-item a {
  display: block;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.toprec-container .toprec-item a img {
  display: block;
  border-radius: 8px 8px 0 0;
}
.toprec-container .toprec-item a p {
  font-size: 1.3rem;
  padding: 1.6rem;
  display: flex;
  align-items: flex-start;
  color: #fff;
  background-color: #245e42;
  border-radius: 0 0 8px 8px;
  flex-grow: 1;
}
.toprec-container .toprec-item a p::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  background-image: url("../../../images/common/top-rec-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0.3rem 0.5rem 0 0;
}

/* その他（広報誌、ブログなど） */
.others-container {
  display: flex;
  flex-flow: column;
  gap: 3.5rem;
}
@media screen and (min-width: 992px) {
  .others-container {
    flex-flow: row;
  }
}
.others-container .others-item {
  width: 100%;
}
.others-container .others-item a {
  display: flex;
}
@media (hover: hover) and (pointer: fine) {
  .others-container .others-item a:hover p {
    color: #55824a;
  }
}
.others-container .others-item a div {
  display: block;
  width: 23%;
  height: fit-content;
  aspect-ratio: 1/1;
  background-size: cover;
  overflow: hidden;
  margin-right: 1.4rem;
  min-width: 77px;
  max-width: 100px;
}
.others-container .others-item a p {
  width: 70%;
  font-size: 1.3rem;
  color: #333;
}
.others-container .others-item a p span {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-decoration: underline;
}

.top-h {
  font-family: "Shippori Mincho";
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1.6rem;
  padding-left: 0.8rem;
  display: flex;
  align-items: flex-end;
  position: relative;
}
@media screen and (min-width: 768px) {
  .top-h {
    font-size: 2.6rem;
    margin-bottom: 3.2rem;
  }
}
.top-h::before {
  content: "";
  display: inline-block;
  height: 65%;
  width: 2px;
  background-color: #55824a;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}
.top-h span {
  font-family: "Times New Roman";
  font-weight: initial;
  color: #6c757d;
  font-size: 1rem;
  margin-left: 1.5rem;
}
@media screen and (min-width: 768px) {
  .top-h span {
    font-size: 1.3rem;
    margin-left: 2rem;
  }
}

/*TOP 予防接種モーダル*/
/*モーダルを開くボタン*/
.modal-open:hover {
  cursor: pointer;
}

/*モーダル本体の指定 + モーダル外側の背景の指定*/
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px 20px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  box-sizing: border-box;
  z-index: 100;
}

/*モーダル本体の擬似要素の指定*/
.modal-container:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

/*モーダル本体に「active」クラス付与した時のスタイル*/
.modal-container.active {
  opacity: 1;
  visibility: visible;
}

/*モーダル枠の指定*/
.modal-body {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 500px;
  width: 90%;
}

/*モーダルを閉じるボタンの指定*/
.modal-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -40px;
  right: -40px;
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}

/*モーダル内のコンテンツの指定*/
.modal-content {
  background: #fff;
  text-align: left;
  padding: 30px;
}

.menkai-btn {
  width: 100%;
  padding: 2rem;
  background-color: #55824a;
  border-radius: 5px;
  font-size: 1.5rem;
}
.menkai-btn a {
  color: #fff;
}
.menkai-btn a .txt::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M9,12.5h1v-2h1c0.3,0,0.5-0.1,0.7-0.3C11.9,10,12,9.8,12,9.5v-1c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3H9V12.5zM10,9.5v-1h1v1H10z M13,12.5h2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-2V12.5z M14,11.5v-3h1v3H14z M17,12.5h1v-2h1v-1h-1v-1h1v-1h-2V12.5z M8,18c-0.5,0-1-0.2-1.4-0.6C6.2,17,6,16.5,6,16V4c0-0.5,0.2-1,0.6-1.4C7,2.2,7.5,2,8,2h12c0.6,0,1,0.2,1.4,0.6S22,3.4,22,4v12c0,0.5-0.2,1-0.6,1.4S20.6,18,20,18H8z M8,16h12V4H8V16z M4,22c-0.5,0-1-0.2-1.4-0.6S2,20.5,2,20V6h2v14h14v2H4z M8,4v12V4z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}

.construct-container:has(.modal-container.active) {
  overflow: hidden;
}